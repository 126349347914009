import React, { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Routes from './routes'

library.add(fab, fas);

class App extends Component {

    render() {
        return (
            <Router>
                <Route path="/" component={Routes} />
            </Router>
        );
    }
}

export default App;
