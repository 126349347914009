import React from 'react';
import loadable from '@loadable/component'
import {Route, Switch} from 'react-router-dom';

const Home = loadable(() => import('./home'))

class Routes extends React.Component {

    render() {

        return (
           <Switch>
               <Route path='/' component={Home}/>
           </Switch>
        );
    }
}


export default Routes